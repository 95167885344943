import React from 'react';
import { Title } from '../Components/Title';
import MenuButton, { ButtonSize } from '../Components/MenuButton';
import { Page } from '../App';
import InputBox from '../Components/InputBox';
import { GameData } from './GamePage';

interface Props {
    setPage: (newPage: Page) => void;
    gameUpdate: (gameData: Partial<GameData>) => void;
}

interface State {
    redPlayer: string,
    bluePlayer: string,
    startValue: number,
    endValue: number,
}

export default class NewGame extends React.Component<Props, State> {
    state:State = {bluePlayer:"", redPlayer:"", startValue: 100, endValue: 5000};
    render() {
        return (
            <React.Fragment>
                <Title/>
                <InputBox theme={0} title="Start Value" type="number" value={this.state.startValue} onTextChange={this.changeStart}/>
                <InputBox theme={1} title="End Value" type="number" value={this.state.endValue} onTextChange={this.changeEnd}/>
                <MenuButton theme={0} text="Start" onClick={this.startGame} size={ButtonSize.slim}/>
            </React.Fragment>
         );
    }
    changeRedPlayer = (value: string) => {this.setState({redPlayer:value})}
    changeBluePlayer = (value: string) => {this.setState({bluePlayer:value})}
    changeStart = (value: string) => {this.setState({startValue: parseInt(value)})} 
    changeEnd= (value: string) => {this.setState({endValue: parseInt(value)})} 
    startGame= () => {
        const {redPlayer, bluePlayer, startValue, endValue} = this.state;
        this.props.gameUpdate({currentLooser:null, redPlayer, bluePlayer, currentPoints:[startValue, startValue], maxPoints: endValue})
        this.props.setPage(Page.Game);
    }
}