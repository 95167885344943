import React, { ReactNode } from 'react';
import styled from 'styled-components';

export enum ButtonSize {
    slim = 1,
    normal = 2, 
}
interface Props {
    theme?:number,
    size?:ButtonSize,
    text: string,
    disabled?: boolean,
    onClick: () => void;
}

const MenuButton = ({size, text, onClick, theme, disabled }: Props) => (
    <BtnDiv size={size || ButtonSize.normal} onClick={() => disabled == true || onClick()} isRed={theme === 0} disabled={disabled == true}>
        {text}
    </BtnDiv>
);
const BtnDiv = styled.div<{size:ButtonSize, isRed:boolean, disabled: boolean}>`
    background-color:${p => p.disabled ? "#6B6B6B": p.isRed ? "#D34949" : "#2297fb"};
    height: ${p => p.size == ButtonSize.normal ? "80" : "40"}px;
    min-width: 40vw;
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.52);
    border-radius: 27px;
    color:white;
    border: 8px solid #FFFFFF;
    display:flex;
    justify-content:center;
    align-items: center;
    text-align:center;
    font-size: 35px;
    font-family: 'Roboto', sans-serif;
    z-index:100;
    padding: 2vh 1vw;
    margin:10px;
`;
export default MenuButton;