import React from 'react';
import styled from 'styled-components';
import { Side } from './GamePage';
import MenuButton from '../Components/MenuButton';
import { Page } from '../App';

interface Props {
    looser: Side;
}

const Victory = ({ looser }: Props) => (
    <React.Fragment>
        <VictoryBanner src={`./Images/${looser == Side.blue ? "Red" : "Blue"}Victory.png`}/>
        <MenuButton text={"Restart"} theme={looser == Side.blue ? Side.red : Side.blue} onClick={() => window.location.reload()}/>
    </React.Fragment>
);
const VictoryBanner = styled.img`
    z-index:5;
    width:100%;
    height:auto;
    z-index: 100;
`;
export default Victory;