import React from 'react';
import { CamPosition } from '../Pages/Background';
import styled from 'styled-components';
import { Side } from '../Pages/GamePage';

interface Props {
    cameraPosition: CamPosition
    isRed: boolean,
    currentLooser: Side | null;
    size: number;
}
const startingY = 545;
const endingY = 810;


const getPosition = (index: number, total:number, isRed: boolean, cameraPosition: CamPosition) => {
    const xGradient = (120/ total);
    if(isRed){
        switch(cameraPosition){
            case CamPosition.Center:
                return 2000 - (window.innerWidth/2 );
            case CamPosition.Left:
                return 229 + (index * -xGradient);
            case CamPosition.Right:
                return 4000 - ((window.innerWidth));
        }
    } else {
        switch(cameraPosition) {
            case CamPosition.Center:
                return 2000 + (window.innerWidth/2) - 35;
            case CamPosition.Left:
                return (window.innerWidth) - 35;
            case CamPosition.Right:
                return 3733 + (index * xGradient);
        }
        
    }
    console.log(cameraPosition)
    return -1;
}

const Army = ({ size, isRed, currentLooser, cameraPosition, }: Props) => {
    
    const spacing = Math.abs(startingY - endingY) / size;
    return (<React.Fragment>
    {
        [ ...(Array(size) as any).keys() ].map((e: null,i: number) => (
            <Soldier
                key={i}
                src={`./Images/${isRed ? "Red" : "Blue"}Soldier.png`}
                transitionDelay={(isRed && currentLooser == Side.red) || (!isRed && currentLooser == Side.blue) ? Math.random()/ 10 : Math.random()/3 }
                x={getPosition(i, size, isRed,cameraPosition)}
                y={startingY + (i * spacing)}
            />
        ))
        
    }    
    </React.Fragment>)
};

const Soldier = styled.img<{x: number, y: number, transitionDelay: number}>`

    position: absolute;
    left: ${p => p.x}px;
    transform:translateY(${p => p.y}px);
    transition: all 5s;
    transition-delay:${p => p.transitionDelay}s;
    z-index:3;
`;

export default Army;