import React from 'react';
import styled, {css} from 'styled-components';
import { Tower, PositionableImage } from './Tower';
import { Side } from '../Pages/GamePage';

interface Props {
    side:Side,
    x: number,
    remaining: number;
}

const Castle = ({side, x, remaining}: Props) => (
    <Wrapper x={x}>
        {/* Back row */}
        <Tower 
            side={side}
            destroyed={remaining < 2}
            positionalData={{
                x:50,
                y:700,
                scale:1.4
            }}
            index={1}
        />
        <Tower 
            side={side}
            destroyed={remaining < 1}
            positionalData={{
                x:0,
                y:725,
                scale:2
            }}
            
            index={2}
        />
        <Tower 
            side={side}
            destroyed={remaining < 3}
            positionalData={{
                x:-30,
                y:860,
                scale:1.6
            }}
            
            index={3}
        />
        {/* Front row */}
        <PositionableImage
            src={'./Images/WallPeice.png'}
            x={150}
            y={888}
            scale={1}
        />
        <Tower 
            side={side}
            destroyed={remaining < 4}
            positionalData={{
                x:120,
                y:810,
                scale:1
            }}
            
            index={4}
        />
        <Tower 
            side={side}
            destroyed={remaining < 4}
            positionalData={{
                x:70,
                y:910,
                scale:1
            }}
            
            index={4}
        />
        <PositionableImage
            src={'./Images/WallPeice.png'}
            x={25}
            y={1100}
            scale={1}
        />
    </Wrapper>
    
);
const Wrapper = styled.div<{x: number}>`
    position:absolute;
    left: ${p => Math.abs(p.x)}px;
    height:1200px;
    width: 400px;
    z-index:2;
    bottom:0;
    ${p => p.x < -1700 && css`
        transform: scaleX(-1);
    `}

`;


export default Castle;