import styled,{keyframes} from "styled-components";
import React from 'react';
import { Side } from "../Pages/GamePage";
interface Props {
    side: Side,
    destroyed: boolean;
    positionalData:{
        x: number,
        y: number,
        scale: number
    }
    index: number;
}
interface State {
    exploding: boolean
    exploded: boolean
}
export class Tower extends React.Component<Props, State> {
    state = {exploding: false, exploded: false};
    componentWillReceiveProps(newProps:Props){
        if(!newProps.destroyed){
            setTimeout(() => this.setState({exploding: false, exploded: false}), 300);
        } 
        if(newProps.destroyed && !this.props.destroyed) {
            
            setTimeout(() => this.setState({exploded: true}),700);
            setTimeout(() => this.setState({exploding: false}),600);
            this.setState({exploding: true});
        }
    }
    public render() {
        const Tower = this.tower;
        const Ruin = this.ruins;
        const Smoke = this.smoke;
        return (
            <div style={{position:"absolute", zIndex:this.props.index}}>
                <Smoke/>
                {!this.state.exploded ? <Tower/> : <Ruin/>}
            </div>
        )
    }
    private smoke = () => (
        <React.Fragment>
            <Smoke src="./Images/smoke.png" x={this.props.positionalData.x} y ={this.props.positionalData.y} scale={3} exploded={this.state.exploding} delay={0}/>
            <Smoke src="./Images/smoke.png" x={this.props.positionalData.x - 10} y ={this.props.positionalData.y - 60} scale={2} exploded={this.state.exploding} delay={0.1}/>
            <Smoke src="./Images/smoke.png" x={this.props.positionalData.x + 10} y ={this.props.positionalData.y - 120} scale={1} exploded={this.state.exploding} delay={0.2}/>
        </React.Fragment>
    )
    private tower = () => <PositionableImage src={`./Images/${this.props.side == Side.blue ? "Blue" : "Red"}Keep.svg`} {...this.props.positionalData}/>
    private ruins = () => (
        <PositionableFire  {...this.props.positionalData} width="62" height="72" viewBox="0 0 62 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 58.5C0.5 60.1219 1.28172 61.7115 2.78817 63.2051C4.29647 64.7006 6.50447 66.0703 9.27305 67.2313C14.8081 69.5525 22.4895 71 31 71C39.5105 71 47.1919 69.5525 52.7269 67.2313C55.4955 66.0703 57.7035 64.7006 59.2118 63.2051C60.7183 61.7115 61.5 60.1219 61.5 58.5C61.5 56.8781 60.7183 55.2885 59.2118 53.7949C57.7035 52.2994 55.4955 50.9297 52.7269 49.7687C47.1919 47.4476 39.5105 46 31 46C22.4895 46 14.8081 47.4476 9.27305 49.7687C6.50447 50.9297 4.29647 52.2994 2.78817 53.7949C1.28172 55.2885 0.5 56.8781 0.5 58.5Z" fill="#323232" stroke="black"/>
            <path d="M61.5 54H0.5V59.4715C0.501968 59.4865 0.505459 59.5108 0.511181 59.5437C0.523273 59.6134 0.545319 59.7218 0.583981 59.8637C0.661259 60.1473 0.805065 60.5652 1.06897 61.0747C1.59557 62.0913 2.60701 63.4856 4.54596 64.9095C8.42965 67.7616 16.062 70.751 31.0083 71.0001C45.9485 71.2491 53.5701 68.2581 57.446 65.2905C59.3822 63.8081 60.3954 62.3233 60.9248 61.2278C61.1901 60.6788 61.3352 60.2247 61.4136 59.9139C61.4528 59.7585 61.4753 59.6389 61.4877 59.5612C61.494 59.5223 61.4977 59.494 61.4997 59.4769L61.5 59.4742V54Z" fill="#323232" stroke="black"/>
            <path d="M0.5 53.5C0.5 55.1219 1.28172 56.7115 2.78817 58.2051C4.29647 59.7006 6.50447 61.0703 9.27305 62.2313C14.8081 64.5525 22.4895 66 31 66C39.5105 66 47.1919 64.5525 52.7269 62.2313C55.4955 61.0703 57.7035 59.7006 59.2118 58.2051C60.7183 56.7115 61.5 55.1219 61.5 53.5C61.5 51.8781 60.7183 50.2885 59.2118 48.7949C57.7035 47.2994 55.4955 45.9297 52.7269 44.7687C47.1919 42.4476 39.5105 41 31 41C22.4895 41 14.8081 42.4476 9.27305 44.7687C6.50447 45.9297 4.29647 47.2994 2.78817 48.7949C1.28172 50.2885 0.5 51.8781 0.5 53.5Z" fill="#323232" stroke="black"/>
            <Flame fill="#FA0000" x={-9} y={0} scale={1} delay={0}/>
            <Flame fill="#FA0000" x={9} y={0} scale={1} delay={0.6}/>
            <Flame fill="#FAFA00" x={11} y={25} scale={0.6} delay={0.3}/>
        </PositionableFire>
    );
}
export const PositionableImage = styled.img<{x: number, y: number, scale: number}>`
    position:absolute;
    transform: ${p => `translate(${p.x}px, ${p.y}px) scale(${p.scale, p.scale})`};
    
`;

const PositionableFire = styled.svg<{x: number, y: number, scale: number}>`
    position:absolute;
    transform: ${p => `translate(${p.x}px, ${180 + p.y}px) scale(${p.scale, p.scale})`};
`;

export const Smoke = styled.img<{x: number, y: number, scale:number, delay:number, exploded:boolean}>`
    position:absolute;
    transform: ${p => `translate(${p.x}px, ${180 + p.y}px) scale(${p.exploded ? p.scale : 0} , ${p.exploded ?  p.scale : 0})`};
    fill: ${p => p.exploded ? "#ffffff00" : "#ffffffff"};
    transition: ${p => p.exploded ? 'all .5s' : ""};
    opacity: ${p => p.exploded ? 0.6 : 1};
    transition-delay: ${p => p.delay}s;
    z-index:3;

`;
const flameAnim = keyframes`
  0%{
    d:path("M 32 64 Q 10 64 10 44 L 32 0 L 54 44 Q 50 64 32 64 Z");
  }
  33%{
    d:path("M 32 64 Q 10 64 10 44 L 25 14 L 54 44 Q 50 64 32 64 Z");
  }
  66%{
    d:path("M 32 64 Q 10 64 10 44 L 48 20 L 54 44 Q 50 64 32 64 Z");
  }
  100%:{
    d:path("M 32 64 Q 10 64 10 44 L 32 0 L 54 44 Q 50 64 32 64 Z");
  }

`;
const Flame = styled.path<{x:number, y:number, scale:number, delay:number}>`
    transform: ${p => `translate(${p.x}px,${p.y}px) scale(${p.scale})`};
    d: path("M 32 64 Q 10 64 10 44 L 32 0 L 54 44 Q 50 64 32 64 Z");
    animation:${flameAnim} 1s linear infinite;
    animation-delay:${p => p.delay}s;
`;