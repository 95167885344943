import React from 'react';
import Background, { CamPosition } from './Pages/Background';
import styled from 'styled-components';
import EntryPage from './Pages/EntryPage';
import NewGame from './Pages/NewGame';
import GamePage, { GameData, Side } from './Pages/GamePage';
import Victory from './Pages/Victory';

export enum Page {
  EntryPage,
  NewGame,
  LoadGame,
  Game,
  VictoryPage,
}
interface  State {
  currentPage: Page,
  cameraPosition: CamPosition,
  gameData: GameData,
}
const gameData: GameData = {
  redPlayer:"",
  bluePlayer:"",
  maxPoints:5000,
  remainingLives: 5,
  currentLooser: null,
  currentPoints: [0,0],
  currentIncrement: 100,
}
class App extends React.Component<{},State> {
  public state:State = {currentPage: Page.EntryPage, cameraPosition: CamPosition.Center, gameData};
  render() {
      return (
      <Wrapper>
        <Background 
          gameData={this.state.gameData}
          />
        {this.renderPage()}
      </Wrapper>
    );
  }
  renderPage() {
    switch(this.state.currentPage) {
      case Page.EntryPage:
        return <EntryPage setPage={this.changePage}/>
      case Page.NewGame:
        return <NewGame setPage={this.changePage} gameUpdate={this.updateGameData}/>
      case Page.Game:
        return <GamePage gameData={this.state.gameData} gameUpdate={this.updateGameData} setPage={this.changePage}/>
      case Page.VictoryPage:
        return <Victory looser={this.state.gameData.currentLooser || Side.red} />
    }
  }
  changeCamPosition = (newPos: CamPosition) => {
    this.setState({cameraPosition: newPos});
  }
  changePage = (newPage: Page) => {
    this.setState({
      cameraPosition: CamPosition.Center,
      currentPage: newPage
    });
  }
  updateGameData = (gameData: Partial<GameData>) => 
    this.setState(o => {

      const newData: GameData = {...o.gameData, ...gameData};
      console.log(o.gameData, gameData, newData);
      return {
        gameData: newData,
        cameraPosition: this.sideToCamera(newData.currentLooser),
      }
    })

    sideToCamera(s:Side | null) {
      if(s == null) return CamPosition.Center;
      switch(s) {
        case Side.red:
          return CamPosition.Left;
        case Side.blue:
          return CamPosition.Right;
      }
    }
}
const Wrapper = styled.div`
  position:absolute;
  font-family: 'Roboto', sans-serif;
  top:0;
  left:0;
  width:100vw;
  height:100%;
  background-color:#91E8F3;
  overflow:hidden;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items: center;
`;
export default App;
