import React, { ReactNode } from 'react';
import { Page } from '../App';
import styled from 'styled-components';
import { Title } from '../Components/Title';
import MenuButton from '../Components/MenuButton';
interface Props {
    setPage:(newPage:Page) => void,
}

const EntryPage = ({ setPage }: Props) => (
    <React.Fragment>
        <Title/>
        <ButtonWrapper>
            <MenuButton text="New Game" theme={0} onClick={() => setPage(Page.NewGame)}/>
        </ButtonWrapper>
    </React.Fragment>
    
);

const ButtonWrapper = styled.div`
    z-index:5;
    width:100vw;
    height:30vh;
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    align-items:center;
`;

export default EntryPage;