import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
    theme?:number,
    title: string,
    type: string,
    value: string | number,
    onTextChange: (data:string) => void;
}

const InputBox = ({ title, theme, type, value, onTextChange }: Props) => (
    <Wrapper isRed={theme === 0}>
        <Tag>{title}</Tag>
        <Field isRed={theme === 0} type={type} value={value} onChange={(e:any) => onTextChange(e.currentTarget.value)}/>
    </Wrapper>
);
const Wrapper = styled.div<{isRed:boolean}>`
    z-index:4;
    width: 60vw;
    display: flex;
    height: 80px;
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.52);
    border-radius: 27px;
    color:white;
    border: 8px solid #FFFFFF;
    background-color: ${p => p.isRed ? "#D34949" : "#2297fb"};
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    align-items: center;
    overflow:hidden;
`;
const Tag = styled.div`
    text-align:center;
    width:40%;
`;
const Field = styled.input<{isRed:boolean}>`
    border: none;
    height: 100%;
    padding-left: 4px;
    width: 70%;
    background-color: ${p => p.isRed ? "#440e0e" : "#0e2744"};
    font-size: 20px;
    color: white;
`;
export default InputBox;