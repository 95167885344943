import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Castle from '../Components/Castle';
import Army from '../Components/Army';
import { GameData, Side } from './GamePage';

export enum CamPosition {
    Left ,
    Center  ,
    Right ,
}
interface Props {
    gameData: GameData;
}
const getCameraPosition = (looser :Side | null) => {
    let camPos = CamPosition.Center;
    if(looser == null) camPos = CamPosition.Center;
    if(looser == Side.red) camPos = CamPosition.Left;
    if(looser == Side.blue) camPos = CamPosition.Right;
    return camPos; 
}
const camOffsets = (t:CamPosition) => {
    switch(t){
        case CamPosition.Left:
            return 0;
        case CamPosition.Center:
            return -2000 + (window.innerWidth/2);
        case CamPosition.Right:
            return -4000 + window.innerWidth; 
    }
}
const Background = ({ gameData }: Props) => (
    <Wrapper offset={camOffsets(getCameraPosition(gameData.currentLooser))}>
        <BgImage src="./Images/Background.svg"/>
        <Castle side={Side.red} x={0} remaining={gameData.remainingLives}/>
        <Castle side={Side.blue} x={-4000 + 400} remaining={gameData.remainingLives}/>
        <Army size={Math.min(gameData.currentPoints[Side.red] / 100, 25)} cameraPosition={getCameraPosition(gameData.currentLooser)} currentLooser={gameData.currentLooser} isRed={true}/>
        <Army size={Math.min(gameData.currentPoints[Side.blue] / 100, 25)} cameraPosition={getCameraPosition(gameData.currentLooser)} currentLooser={gameData.currentLooser} isRed={false}/>
    </Wrapper>
);

const Wrapper = styled.div<{offset:number}>`
    position:absolute;
    transition: left 5s;
    left: ${p => p.offset}px;
    overflow:none;
    bottom:0;
    background-color:#91E8F3;
`;

const BgImage = styled.img`
`;

export default Background;