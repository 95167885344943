import React from 'react';
import styled from 'styled-components';
import MenuButton, { ButtonSize } from '../Components/MenuButton';
import { Page } from '../App';

export interface GameData {
    redPlayer: string,
    bluePlayer: string,
    maxPoints: number,
    remainingLives: number,
    currentLooser: Side | null,
    currentPoints:number[],
    currentIncrement: number,
}
export enum Side{
    red = 0,
    blue = 1,
}
interface Props {
    gameData: GameData;
    gameUpdate: (gameData: Partial<GameData>) => void;
    setPage: (newPage: Page) => void;
}
interface State{
    
    initialPoints: number;
}
let initialPointValue: number;
class GamePage extends React.Component<Props, State>{
    state: State = {initialPoints: -1}
    render() {
        const { gameData, gameUpdate, setPage } = this.props;
        return  (
            <Wrapper>
                <WinBox>
                    <MenuButton 
                        text={this.isLastRound(Side.red)? "Red Lose" : "Red Defeat"}
                        theme={0} 
                        onClick={this.HandleWin(Side.red)}
                    />  
                    <MenuButton
                        text={this.isLastRound(Side.blue)? "Blue Lose" : "Blue Defeat"}
                        theme={1} 
                        onClick={this.HandleWin( Side.blue)}
                    />    
                </WinBox>
                <SlidingData side={gameData.currentLooser}>
                    <LooserText side={gameData.currentLooser}>{gameData.currentLooser == null ? '' : gameData.currentLooser == Side.blue ? "Blue" : "Red"} Limit</LooserText>
                    <BoldenedText>{gameData.currentPoints[gameData.currentLooser || 0]}</BoldenedText>
                    <MenuButton 
                        text="Reset" 
                        size={ButtonSize.slim} 
                        onClick={this.HandleReset} 
                        disabled={gameData.currentLooser == null || (gameData.currentPoints[gameData.currentLooser] - gameData.currentIncrement) < 0 }
                        theme={gameData.currentLooser || Side.red}
                    />
                        
                </SlidingData>
            </Wrapper>
        );
    }
    HandleReset = () => {
        const {currentPoints, currentLooser, currentIncrement} = this.props.gameData; 
        if(this.state.initialPoints == -1){
            this.setState({initialPoints: currentPoints[currentLooser!]});
        }
        this.props.gameUpdate({
            currentPoints: currentPoints.map( (p,i) => i == currentLooser ? p - currentIncrement : p )
        });
        }
    HandleWin = (
            looser:Side,
        ) => () => {
        const {currentPoints, currentLooser, remainingLives, currentIncrement, maxPoints} = this.props.gameData;
        const resetPoints = currentPoints.map((p,i) => i == currentLooser && this.state.initialPoints != -1 ? this.state.initialPoints : p);
        
        this.props.gameUpdate({
            currentPoints: resetPoints.map( (p,i) => i == looser ? Math.min(Math.max(p) + currentIncrement, maxPoints) : p ),
            currentLooser:looser,
            currentIncrement: Math.floor((currentPoints.reduce((m:number , i:number) => Math.max(i,m),0) + currentIncrement) / 1000) * 100 + 100,
            remainingLives: looser == currentLooser ?  remainingLives - 1 : 4,
        });
        if(this.isLastRound(looser)) {
            this.props.setPage(Page.VictoryPage);
            return;
        }
        
        this.setState({initialPoints : -1});
    }
    isLastRound(side:Side) {
        const {currentPoints, currentLooser, remainingLives, currentIncrement, maxPoints} = this.props.gameData;
        return (
            currentLooser != null && currentPoints[currentLooser] === maxPoints ||
            initialPointValue == maxPoints ||
            currentLooser == side && remainingLives == 1
        )
    }
}
const BoldenedText= styled.div`
    font-size:30px;
    font-weight: bold;
    color:black;
    
    width:100%;
    text-align: center;
`;
const LooserText = styled(BoldenedText)<{ side :Side | null}>`
    color:${p => p.side == null ? 'black' : p.side == Side.red ? '#D34949' : '#2297FB'};
`;
const Wrapper = styled.div`
`;
const SlidingData = styled.div<{side:Side | null}>`
    position:relative;
    text-align:right;
    left:${p => p.side == null ? 'calc( 50vw - 22vw)' : p.side == Side.red ? 'calc( 100vw - 44vw)' : 0};
    width: 44vw;
    transition:all 4s;
    display:flex;
    flex-direction:column;
    align-items:center;
`;
const WinBox = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
`;


export default GamePage;